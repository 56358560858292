import React from 'react';
import SEO from 'components/shared/SEO';
import KSeF from 'components/KSeF';
import { graphql } from 'gatsby';

const functionalities = {
  heading: 'W ramach produktu dostępne są następujące funkcjonalności:',
  lists: [
    {
      listLabel: 'Obsługa wysyłki faktury do KSeF (eksport).',
      listItems: [
        '<strong>Tworzenie faktur</strong> w postaci ustrukturyzowanej',
        '<strong>Wizualizację pliku XML</strong> reprezentującego fakturę w postaci ustrukturyzowanej',
        '<strong>Walidacje pliku XML</strong> przed wysyłką pod kontem zgodności ze schematem XDS dostarczonym przez MF',
        'Prezentację i <strong>edycję danych z faktury</strong> w celu analizy wysyłanego pliku i ewentualnej modyfikacji przez wysyłką',
        'Nawiązanie komunikacji z KSeF i <strong>autoryzacja</strong> przy użyciu <strong>certyfikatu</strong> reprezentującego pieczęć',
        '<strong>Wysyłkę plików</strong> XML do KSeF',
        'Pobranie <strong>potwierdzenia UPO</strong> oraz <strong>numeru KSeF</strong>',
        'Możliwość przeglądu <strong>logu wysyłek</strong> faktur wraz z historią wysyłki',
        '<strong>Komunikacje z użytkownikiem</strong> w postaci <strong>e-mail</strong> zawierającego logi z ostatniego przebiegu wysyłki',
      ],
    },
    {
      listLabel: 'Monitor komunikatów wejściowych oraz wyjściowych',
      listItems: [
        'Raport fraktur wychodzących w żądanym okresie',
        'Raport faktur przychodzących w żądanym okresie',
      ],
    },
    {
      listLabel: 'Obsługa pobrania faktur z KSeF (import)',
      listItems: [
        '<strong>Pobranie plików</strong> XML zawierających faktury w postaci ustrukturyzowanej z KSeF',
        'Możliwość <strong>uzupełnienia nr zamówienia</strong> SAP',
        '<strong>Wizualizacja</strong> faktury w postaci pliku <strong>PDF</strong>',
        '<strong>Wysyłka pliku XML i/lub PDF</strong> na wskazany adres <strong>e-mail</strong>',
        'Przetworzenie pobranych faktur i rejestracja ich w SAP w postaci <strong>wstępnie wprowadzonej faktury</strong>',
        'Komunikacje z <strong>użytkownikiem</strong> w postaci <strong>e-mail</strong> zawierającego logi z ostatniego przebiegu pobrań',
      ],
    },
  ],
};

const systemRequirements = {
  heading: 'Wymagania systemowe',
  lists: [
    {
      listLabel: 'Produkt Aseti-KSeF może zostać zaimplementowany na systemach',
      listItems: ['SAP ECC w wersji 6.0 lub wyższej', 'S/4 HANA'],
    },
    {
      listLabel:
        'W celu poprawnego działania aplikacji oraz nawiązania połączenia z system KSeF wymagane są następujące komponenty systemu SAP',
      listItems: [
        'Wersja komponentu BASIS co najmniej na poziomie Net Weaver 7.4 ',
        'CryptoLib: Wersja 8.4.31 lub wyższa',
        'Połączenie jest szyfrowane z wykorzystaniem protokołu HTTPS i TLS 1.2',
      ],
    },
  ],
};

const KSEFPage = ({ data }) => {
  return (
    <>
      <SEO title="Aseti - KSeF" />
      <main>
        <KSeF
          functionalities={functionalities}
          systemRequirements={systemRequirements}
          visualizationImg={data.visualization.childImageSharp.fluid}
        />
      </main>
    </>
  );
};

export default KSEFPage;

export const query = graphql`
  {
    visualization: file(name: { eq: "ksef-visualization" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
