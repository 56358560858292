import React from 'react';
import styled from 'styled-components';
import LazyImage from 'components/shared/LazyImage';

const heading = 'Aseti-KSeF';

const desc =
  "Produkt <strong>'Aseti-KSeF'</strong> umożliwia, po jego implementacji w systemie SAP ECC oraz S/4 HANA, <strong>wygenerowanie ustrukturyzowanej faktury</strong> w postaci komunikatu XML oraz <strong>komunikacją z Krajowym Systemem e-Faktur (KSeF)</strong> przy użyciu API udostępnionego przez Ministerstwo Finansów (MF).";

const visualizationHeading =
  'Schemat poglądowy komunikatów pomiędzy systemem SAP, a KSeF.';

const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    max-width: 1000px;
  }
  @media (max-width: 1080px) {
    padding: 0 clamp(2rem, 8vw, 80px);
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 32px auto 70px;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  margin-top: 100px;
  padding: 60px 45px 70px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 20px;
  @media (max-width: 1080px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 400px) {
    padding: 40px 20px 70px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-top: 130px;
  }

  ${({ theme }) => theme.mq.xxl} {
    margin-top: 160px;
  }
`;

const Heading = styled.h1`
  width: 100%;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.lgm};
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 70px;
    background: rgb(112, 112, 112);
    margin: 25px 0 30px;
  }
`;

const VisualizationHeading = styled.h2`
  width: 100%;
  margin-top: 20px;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.md};
`;

const Description = styled.div`
  line-height: 1.4;
  &:last-child {
    &:after {
      content: '';
      display: block;
      margin: 50px auto 0px;
      width: 65px;
      height: 1px;
      background: rgb(0, 0, 0);
    }
  }
`;

const ListsWrapper = styled.div`
  margin: 40px 0;
`;

const ListHeading = styled.h2`
  margin-bottom: 24px;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.bold};
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: ${({ theme }) => theme.fontSize.lgm};
  }
`;

const ListLabel = styled.p`
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.m};
  margin-bottom: 10px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  list-style-type: disc;
  list-style-position: inside;
`;

const ListItem = styled.li`
  line-height: 1.4;
`;

const EndingInfo = styled.p`
  a {
    color: inherit;
    text-decoration: none;
    margin-left: 5px;
  }
`;

const KSeF = ({ functionalities, systemRequirements, visualizationImg }) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Content>
          <Heading dangerouslySetInnerHTML={{ __html: heading }} />
          <Description dangerouslySetInnerHTML={{ __html: desc }} />
          <VisualizationHeading
            dangerouslySetInnerHTML={{ __html: visualizationHeading }}
          />
          <LazyImage src={visualizationImg} alt="visualzation - ksef" />
          <ListsWrapper>
            <ListHeading
              dangerouslySetInnerHTML={{ __html: functionalities.heading }}
            />
            {functionalities?.lists?.map(item => (
              <List key={item.listLabel}>
                <ListLabel
                  dangerouslySetInnerHTML={{ __html: item.listLabel }}
                />
                {item.listItems?.map(listItem => (
                  <ListItem
                    key={`listItem - ${item.listLabel}`}
                    dangerouslySetInnerHTML={{ __html: listItem }}
                  />
                ))}
              </List>
            ))}
            <ListHeading
              dangerouslySetInnerHTML={{ __html: systemRequirements.heading }}
            />
            {systemRequirements?.lists?.map(item => (
              <List key={item.listLabel}>
                <ListLabel
                  dangerouslySetInnerHTML={{ __html: item.listLabel }}
                />
                {item.listItems?.map(listItem => (
                  <ListItem
                    key={`listItem - ${item.listLabel}`}
                    dangerouslySetInnerHTML={{ __html: listItem }}
                  />
                ))}
              </List>
            ))}
            <ListLabel
              dangerouslySetInnerHTML={{
                __html:
                  'Zapewniamy także wsparcie SAP BASIS niezbędne do uruchomienia naszego rozwiązania w Państwa systemie SAP.',
              }}
            />
          </ListsWrapper>
          <EndingInfo>
            Wszystkich zainteresowanych zapraszamy do kontaktu
            <a href="mailto: biuro@aseti.pl">biuro@aseti.pl</a>
          </EndingInfo>
        </Content>
      </InnerWrapper>
    </Wrapper>
  );
};

export default KSeF;
